import React from 'react';
import PropTypes from 'prop-types';
import { I18n } from 'react-polyglot';
import { graphql } from 'gatsby';

import withSetLocale from '../../components/i18n/withSetLocale';

import Layout from '../../components/layout';
import BlogPage from '../../components/BlogPage';

const Blog = ({ data, pageContext: { locale, messages } }) => (
    <Layout>
        <I18n locale={locale} messages={messages}>
            <BlogPage locale={locale} data={data} />
        </I18n>
    </Layout>
);

Blog.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired,
};

export default withSetLocale()(Blog);

export const query = graphql`
    query BlogQuery {
        posts: allMdx(
            sort: { order: DESC, fields: [fileAbsolutePath] }
            filter: { fileRelativePath: { glob: "**/blog/**" } }
        ) {
            edges {
                node {
                    id
                    path
                    date
                    frontmatter {
                        title
                        authors
                        tags
                        excerpt
                        cover_image {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 1920, quality: 80) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                        thumbnail_image {
                            publicURL
                            childImageSharp {
                                fluid(maxWidth: 370, quality: 80) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        }
        team: allMdx(filter: { fileRelativePath: { glob: "**/team/**" } }) {
            edges {
                node {
                    id
                    frontmatter {
                        name
                        shortname
                        fullname
                        link
                        picture
                        picture_sp
                    }
                }
            }
        }
    }
`;
